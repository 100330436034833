import {getContext, setContext} from "svelte";
import {NavCtx, type NavPopFn} from "./nav";

const NAV_KEY = Symbol();

export function get_nav_context(): NavCtx | undefined {
  return getContext<NavCtx>(NAV_KEY) ?? undefined;
}

export function require_nav_context(): NavCtx {
  const ctx = get_nav_context();
  if (ctx) {
    return ctx;
  } else {
    throw "no navigation context";
  }
}

export function push_nav_context(): [NavCtx, NavPopFn] {
  let parent = get_nav_context();
  let ctx = setContext(NAV_KEY, new NavCtx());
  if (parent) {
    return [ctx, parent.push(ctx)];
  } else {
    return [ctx, () => {}];
  }
}
