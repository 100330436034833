import type {Readable, Subscriber, Unsubscriber} from "svelte/store";

export type DynDerivedSetFn<T> = (value: T) => void;
export type DynDerivedFn<T, U> = (values: U[], set: DynDerivedSetFn<T>) => void;
/**
 * A _**dynamic** derived_ store, which can have input stores dynamically added
 * and removed.
 *
 * To add a store, pass it to the `use()` function. To remove a store, invoke
 * the unsibscribe function returned from `use()`.
 */
export interface DynDerived<T, U = T> extends Readable<T> {
  /**
   * Add an input store to use when deriving the value for this store.
   *
   * The store can later be removed using the returned unsubscribe function.
   *
   * @param store - An additional input store from which to derive the value for
   * this store.
   *
   * **NOTE:** It is invalid to provide a `store` that is _already in use_ by
   * this store.
   *
   * @returns An unsubscribe function that can be invoked to remove the input
   * `store` from this derived store.
   */
  use(this: void, store: Readable<U>): Unsubscriber;
}

/**
 * Create a _**dynamic** derived_ store.
 *
 * A dynamic derived store is similar to a standard Svelte `derived` store,
 * except that the list of input stores can be updated after instantiation, with
 * input stores being dynamically added and removed as needed.
 *
 * @param fn - The update function to invoke whenever any of the dependent
 * stores update, or whenever stores are added or removed.
 *
 * The `fn` function must accept two parameters:
 *
 * - `values`: an array of **defined** values. The order of values in the array
 *   is in _registration order_. N.B. `undefined` values are **excluded**, so
 *   the array may contain less values than the number of registered stores.
 * - `set`: a `set` function to invoke with the new value for this store.
 *
 * @returns {DynDerived<T, U>} A `DynDerived` store, which is a `Readable` store
 * that also provides a `use()` function to dynamically add dependent stores.
 *
 * ## Examples
 *
 * A simple dynamic store that simply returns the number of subscribed defined
 * strings:
 *
 * > ```
 * > const count = dynderived((values: string[], set: (value: number) => void) => {
 * >   set(values.length);
 * > })
 * >
 * > const string1 = writable<string>("first");
 * > const unsub1 = count.use(string1);
 * > console.log($count); // 1
 * >
 * > const string2 = writable<string>();
 * > const unsub2 = count.use(string2);
 * > console.log($count); // 1 - because string2 is currently undefined
 * >
 * > const string3 = writable<string>("third");
 * > const unsub3 = count.use(string3);
 * > console.log($count); // 2 - because string2 is still undefind
 * >
 * > $string2 = "second";
 * > console.log($count); // 3 - because string2 is now defined
 * >
 * > unsub2(); // remove string2 from the store
 * > console.log($count); // 2
 * > ```
 *
 * ## See also
 *
 * - `DynDerived` - the interface for the dynamic derived store
 */
export function dynderived<T, U = T>(fn: DynDerivedFn<T, U>): DynDerived<T, U> {
  const values: (U | undefined)[] = [];
  const stores: Readable<U>[] = [];
  const subscribers: Subscriber<T>[] = [];
  let assigned = false;
  let current: T;

  function set(value: T): void {
    if (value !== current) {
      assigned = true;
      current = value;
      for (const run of subscribers) {
        run(value);
      }
    }
  }

  function update() {
    const clean: U[] = [];
    for (const value of values) {
      if (value !== undefined) {
        clean.push(value);
      }
    }
    fn(clean, set);
  }

  return {
    use: (store: Readable<U>): Unsubscriber => {
      if (stores.indexOf(store) !== -1) {
        throw `dynderived: use: invalid store: already in use`;
      }

      values.push(undefined);
      stores.push(store);
      update();

      store.subscribe((value) => {
        const index = stores.indexOf(store);
        if (index !== -1) {
          values[index] = value;
          update();
        }
      });

      return () => {
        const index = stores.indexOf(store);
        if (index !== -1) {
          values.splice(index, 1);
          stores.splice(index, 1);
          update();
        }
      };
    },
    subscribe: (run: Subscriber<T>): Unsubscriber => {
      subscribers.push(run);
      if (assigned) {
        run(current);
      }
      return () => {
        const index = subscribers.indexOf(run);
        if (index !== -1) {
          subscribers.splice(index, 1);
        }
      };
    },
  };
}
