export type UrlParam = string | number | boolean | Date | null | undefined;
export function is_url_param(param: any): param is UrlParam {
  switch (typeof param) {
    case "string":
    case "number":
    case "boolean":
    case "undefined":
      return true;
    case "object":
      return param === null || param instanceof Date;
    default:
      return false;
  }
}
export type UrlParams = {
  [name: string]: UrlParam;
};

export function CgUrl() {}

/**
 * CgUrl "class methods".
 */
CgUrl.uri_encode_fragment_component = function (value: string) {
  return CgUrl.uri_encode_query_component(value);
};

CgUrl.uri_encode_query_component = function (value: string) {
  /*
   * ECMA 262 defines "encodeUriComponent" to percent-escape all
   * characters not in "uriUnescaped", which matches
   * /[-_.!~*'()a-zA-Z0-9]/.
   *
   * RFC 3986 states that a valid "query" string is:
   *
   *   query         = *( pchar / "/" / "?" )
   *   pchar         = unreserved / pct-encoded / sub-delims / ":" / "@"
   *   unreserved    = ALPHA / DIGIT / "-" / "." / "_" / "~"
   *   pct-encoded   = "%" HEXDIG HEXDIG
   *   sub-delims    = "!" / "$" / "&" / "'" / "(" / ")"
   *                 / "*" / "+" / "," / ";" / "="
   *
   * Which is the set of "uriUnescaped", as well as [/?:@$&+,;=].  So we
   * safely allow the additional characters matching /[:@$,]/.
   */
  return encodeURIComponent(value)
    .replace(/%3A/gi, ":")
    .replace(/%40/g, "@")
    .replace(/%24/g, "$")
    .replace(/%2C/gi, ",");
};

CgUrl.uri_encode_segment = function (segment: string) {
  /*
   * ECMA 262 defines "encodeUriComponent" to percent-escape all
   * characters not in "uriUnescaped", which matches
   * /[-_.!~*'()a-zA-Z0-9]/.
   *
   * RFC 3986 states that a valid "segment" string is:
   *
   * segment       = *pchar
   * segment-nz    = 1*pchar
   * segment-nz-nc = 1*( unreserved / pct-encoded / sub-delims / "@" )
   *               ; non-zero-length segment without any colon ":"
   *
   * pchar         = unreserved / pct-encoded / sub-delims / ":" / "@"
   *
   * pct-encoded   = "%" HEXDIG HEXDIG
   *
   * unreserved    = ALPHA / DIGIT / "-" / "." / "_" / "~"
   * sub-delims    = "!" / "$" / "&" / "'" / "(" / ")"
   *               / "*" / "+" / "," / ";" / "="
   *
   * Which is the set of "uriUnescaped", as well as [:@$&+,;=].
   * So we can safely allow the additional characters matching
   * /[@$+,&=]/.
   *
   * We won't allow ":" as it is not legal to start a "path" in a
   * "relative-ref" URI with a colon.
   */
  return encodeURIComponent(segment)
    .replace(/%40/g, "@")
    .replace(/%24/g, "$")
    .replace(/%2B/gi, "+")
    .replace(/%2C/gi, ",")
    .replace(/%26/g, "&")
    .replace(/%3D/gi, "=");
};

CgUrl.uri_encode_authority = function (authority: string) {
  // We also need to allow for the ":" in an authority specification.
  return CgUrl.uri_encode_segment(authority).replace(/%3A/gi, ":");
};

/**
 * CgUrl.extract_object_id_segment
 *
 * Extract the object ID (typically a GUID, but may something other) from
 * a URL.
 *
 * This function is only defined to handle object ID URLs provided in
 * one of the following forms:
 *
 *     object-id-string
 *     /path/segments/before/object-id-string
 *     http://some.host/path/segments/before/object-id-string
 *     https://some.host/path/segments/before/object-id-string
 *
 * The URLs are allowed to have a query ("?") and/or a fragment ("#")
 * appended.  The query and fragment will be ignored.
 *
 * The path of the URL MUST NOT end with a trailing "/".  If it does,
 * then this algorithm will consider that there is no object ID.
 *
 * If no object ID can be found, this function will return undefined.
 */
CgUrl.extract_object_id_segment = function (url: string): string | void {
  const matches = url.match(new RegExp("([^/?#]*)([?#].*)?$"));
  if (matches && matches[1]) {
    return matches[1];
  }
};

/**
 * CgUrl.extract_qualified_object_id_segments
 *
 * Extract the "qualified" object ID (typically a GUID, but may something
 * other) from a URL given a target prefix URL.
 *
 * This function is only defined to handle object ID URLs provided in one
 * of the following forms:
 *
 *     object-id-string
 *     /path/segments/before/object-id-string
 *     http://some.host/path/segments/before/object-id-string
 *     https://some.host/path/segments/before/object-id-string
 *
 * The object ID URLs are allowed to have a query ("?") and/or a fragment
 * ("#") appended.  The query and fragment will be ignored.
 *
 * This function is only defined to handle prefix URLs provided in one of
 * the following forms:
 *
 *     /
 *     /path/segments
 *     /path/segments/
 *     http://some.host/path/segments
 *     http://some.host/path/segments/
 *     https://some.host/path/segments
 *     https://some.host/path/segments/
 *
 * The prefix URLs are NOT assumed to NOT have a query ("?") and/or a
 * fragment ("#") appended.
 *
 * If no qualified object ID can be found, this function will return undefined.
 */
CgUrl.extract_qualified_object_id_segments = function (object_url: string, prefix_url: string): string | undefined {
  const path_matches = object_url.match(new RegExp("([^?#]*)([?#].*)?$"));
  if (path_matches && path_matches[1]) {
    const object_segments = path_matches[1].split("/");
    if (object_segments.length === 1) {
      return object_segments[0];
    }
    const prefix_segments = prefix_url.split("/");
    for (let i = 0; i < object_segments.length; i++) {
      const partial_path = object_segments.slice(i).join("/");
      for (let j = 0; j < prefix_segments.length; j++) {
        const prefix_tail = prefix_segments.slice(j).join("/");
        if (prefix_tail.length) {
          if (partial_path.indexOf(prefix_tail) === 0) {
            const object_tail = partial_path.slice(prefix_tail.length);
            return object_tail;
          }
        }
      }
    }
  }
};

CgUrl.url_param_to_string = function (param?: UrlParam) {
  if (param === null || param === undefined) {
    return "";
  } else if (typeof param === "string") {
    return param;
  } else if (typeof param === "number" || typeof param === "boolean") {
    return param.toString();
  } else if (param instanceof Date) {
    return param.toISOString();
  } else {
    throw `invalid_param: A template parameter must not be of type: ${typeof param}`;
  }
};

CgUrl.url_from_template = function (template_url: string, url_params?: UrlParams) {
  /*
   * This function has been adapted from the 'setUrlParams' used by the
   * angular.js "$resource" service.
   */

  url_params = url_params ?? {};

  const used_params: string[] = [];
  let last_end = 0;
  let current_start = last_end;

  let url = "";
  let re = /[:!][+]?\w+/g;

  function noop_encoder(value: string) {
    return value;
  }

  // Cycle over the template URL to extract the parameter names.
  for (;;) {
    const match = re.exec(template_url);
    let param = "";
    let encode_param = true;
    if (!match) {
      // There are no more matches, so just append the last part of the
      // URL.
      url = url + template_url.substring(last_end);
      break;
    } else {
      // First, copy over the part of the URL that is between the end of
      // the last match and the start of the current match.
      current_start = re.lastIndex - match[0].length;
      url = url + template_url.substring(last_end, current_start);
      // Next, append the parameter if it exists.
      if (/^:\d+$/.test(match[0])) {
        // This is a port specification, not a parameter specification.
        param = match[0];
      } else {
        // Extract the parameter name from the parameter specification.
        let param_name = match[0].substring(1);
        let param_modifier = null;
        if (param_name[0] === "+") {
          param_modifier = "+";
          param_name = param_name.substring(1);
        }
        if (param_modifier === "+") {
          encode_param = false;
        }
        if (url_params.hasOwnProperty(param_name)) {
          used_params.push(param_name);
          param = CgUrl.url_param_to_string(url_params[param_name]);
          if (match[0].charAt(0) === "!") {
            param = CgUrl.extract_qualified_object_id_segments(param, url) ?? "";
          }
        } else {
          // Non-existent parameters are ignored.
          param = "";
        }
      }

      // Determine how we should encode this parameter.
      let encoder = noop_encoder;
      if (url.indexOf("#") !== -1) {
        // We have at least a query already.
        encoder = CgUrl.uri_encode_fragment_component;
      } else if (url.indexOf("?") !== -1) {
        // We have at least a query already.
        encoder = CgUrl.uri_encode_query_component;
      } else {
        // We probably have a path at this stage, but we should also check
        // to see if we are only at the scheme or authority.
        if (url.indexOf("/") === -1) {
          // No path-separators yet, so we are probably encoding a path.
          // Although, we could be starting with a scheme.  Fortunately, a
          // valid scheme will not be affected by the segment encoding.
          encoder = CgUrl.uri_encode_segment;
        } else if (url === "/" || new RegExp("^/[^/]").test(url)) {
          // The URL is simply an absolute path.
          encoder = CgUrl.uri_encode_segment;
        } else if (new RegExp("^([a-zA-Z][-+.a-zA-Z0-9]*)?:?//[^/]+/").test(url)) {
          // The URL has "authority", and at least a base path, so we
          // would now be into the "path" section of the URL.
          encoder = CgUrl.uri_encode_segment;
        } else if (new RegExp("^([a-zA-Z][-+.a-zA-Z0-9]*)?:?//[^/]*$").test(url)) {
          // The URL is only up to the "authority" section.
          encoder = CgUrl.uri_encode_authority;
        }
      }

      // Append the parameter to the URL constructed so far.
      url = url + (encode_param ? encoder(param) : param);
      // Update the index for the end of the last match.
      last_end = re.lastIndex;
    }
  }

  for (let [key, value] of Object.entries(url_params)) {
    if (used_params.indexOf(key) === -1) {
      key = CgUrl.uri_encode_query_component(key);
      value = CgUrl.url_param_to_string(value);
      value = CgUrl.uri_encode_query_component(value);
      if (value.length > 0) {
        url = url + (url.indexOf("?") === -1 ? "?" : "&");
        url = url + key + "=" + value;
      }
    }
  }

  // It is possible (even probable) that the "path" section of the URL may
  // be terminated by one or more "/" path separators.  We should trim
  // these, but only from the end of the path.  Compacting multiple "/"
  // path separators at the start or in the middle, would change which
  // resource was being requested.

  // From RFC 3986 Appendix B (modified for literal "?" match):
  var pattern = "^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)([?]([^#]*))?(#(.*))?";
  //              12            3  4          5       6   7        8 9
  //
  // $1 = scheme ":"
  // $2 = scheme
  // $3 = "//" authority
  // $4 = authority
  // $5 = path
  // $6 = "?" query
  // $7 = query
  // $8 = "#" fragment
  // $9 = fragment
  //
  const components = url.match(pattern);
  if (components) {
    let clean_url = "";
    let scheme = components[1]; // scheme ":"
    let authority = components[3]; // "//" authority
    let path = components[5]; // path
    let query = components[6]; // "?" query
    let fragment = components[8]; // "#" fragment

    if (authority === "//") {
      // This is should actually be treated as the start of the path.
      path = authority + (path || "");
      authority = "";
    }

    if (scheme) {
      clean_url = clean_url + scheme;
    }
    if (authority) {
      clean_url = clean_url + authority;
    }
    if (path) {
      let component = path.replace(new RegExp("/+$"), "");
      component = component.length ? component : "/";
      clean_url = clean_url + component;
    }
    if (query) {
      clean_url = clean_url + query;
    }
    if (fragment) {
      clean_url = clean_url + fragment;
    }

    url = clean_url;
  }

  return url;
};
