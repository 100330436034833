<script lang="ts">
  import {onDestroy, onMount} from "svelte";
  import {push_nav_context} from "./context";
  import type {DestroyRouterFn, NavLevel, NavParamFormats, NavRouterType, NavTargeted} from "./nav";
  import type {Unsubscriber} from "svelte/store";

  export let router: NavRouterType | undefined = undefined;
  export let level: NavLevel | undefined = undefined;
  export let path: string | undefined = undefined;
  export let params: NavParamFormats | undefined = undefined;
  export let external: boolean | undefined = undefined;
  export let label: string | undefined = undefined;
  export let group: boolean | undefined = undefined;
  export let nest: boolean | undefined = undefined;
  export let initial: boolean | undefined = undefined;
  export let targeted: NavTargeted | undefined = undefined;

  const [nav, pop] = push_nav_context();
  let unsub_targeted: Unsubscriber | undefined = undefined;
  let unmount_router: DestroyRouterFn | undefined = undefined;

  function clean_targeted() {
    if (unsub_targeted) {
      unsub_targeted();
      unsub_targeted = undefined;
    }
  }

  $: nav.configure({level, path, params, external, label, group, nest, initial});
  $: if (targeted) {
    clean_targeted();
    unsub_targeted = nav.targeted.subscribe(targeted);
  } else {
    clean_targeted();
  }

  onMount(() => {
    if (router === "hash") {
      unmount_router = nav.mount_hash_router();
    }
  });

  onDestroy(() => {
    clean_targeted();
    if (unmount_router) {
      unmount_router();
    }
    pop();
  });
</script>

{#if group || $nav.active}
  <slot {...$nav.route} nested={$nav.nested} params={$nav.params} target={$nav.target} />
{/if}
