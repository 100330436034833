import {dynderived, type DynDerived} from "$lib/store/dynderived";
import type {Readable, Subscriber, Unsubscriber} from "svelte/store";

export type NavLevel = "primary" | "secondary";
export type NavRouterType = "hash";
export type NavParamFormat = "string" | "number" | "boolean";
export type NavParamType = string | number | boolean;

export interface NavParamFormats {
  [name: string]: NavParamFormat;
}

export interface NavParams {
  [name: string]: NavParamType | undefined;
}

export interface RouteParams {
  [name: string]: string;
}

export interface NavItemOptions {
  level?: NavLevel;
  path?: string;
  params?: NavParamFormats;
  external?: boolean;
  label?: string;
  group?: boolean;
  nest?: boolean;
  initial?: boolean;
}

export interface NavItem extends NavItemOptions {
  target: boolean;
  active: boolean;
  items: NavItem[];
}

type PathMatch = {
  matched: boolean;
  path?: string;
  nested?: string;
  params?: RouteParams;
};

export type NavPopFn = () => void;
export type DestroyRouterFn = () => void;
export type NavTargeted = (nav?: NavCtx) => void;

export class NavCtx implements Readable<NavCtx> {
  #parent?: NavCtx;
  #items: NavCtx[];
  #subscriptions: Subscriber<NavCtx>[];
  #router_type?: NavRouterType;
  #options: NavItemOptions;
  #regexp?: RegExp;
  #target: boolean;
  #targeted: DynDerived<NavCtx | undefined>;
  #active: boolean;
  #route?: RouteParams;
  #nested?: string;
  #params?: NavParams;
  subscribe: (run: Subscriber<NavCtx>) => Unsubscriber;

  constructor() {
    this.#items = [];
    this.#subscriptions = [];
    this.#options = {};
    this.#target = false;
    this.#active = false;
    this.subscribe = (run) => {
      this.#subscriptions.push(run);
      run(this);
      return () => {
        const index = this.#subscriptions.indexOf(run);
        if (index >= 0) {
          this.#subscriptions.splice(index, 1);
        }
      };
    };

    this.#targeted = dynderived((values, set) => {
      const target = values.filter((nav) => !!nav?.target);
      set(target[0]);
    });
    this.#targeted.use(this);
  }

  push(ctx: NavCtx): NavPopFn {
    if (ctx.#parent) {
      throw "invalid nav context push: context already has a parent";
    }
    this.#items.push(ctx);
    ctx.#parent = this;
    const unreg = this.#targeted.use(ctx.#targeted);

    return () => {
      if (ctx.#parent !== this) {
        throw "invalid nav context pop: unexpected parent";
      }
      unreg();
      ctx.#parent = undefined;
      ctx.#notify("structure");

      const index = this.#items.indexOf(ctx);
      if (index >= 0) {
        this.#items.splice(index, 1);
      }
      this.#notify("structure");
    };
  }

  configure(options: NavItemOptions) {
    this.#options = {...options};
    if (this.#options.path !== undefined) {
      let pattern = this.#options.path.replace(/:([^:/]+)/g, "(?<$1>[^/]+)");
      let nest = this.#options.nest ? `(?<$nest>.*)` : "";
      this.#regexp = new RegExp(`^(?<$path>${pattern})${nest}$`);
    } else {
      this.#regexp = undefined;
    }
    this.#notify("structure");
  }

  mount_hash_router(): DestroyRouterFn {
    const hash_change = (event: HashChangeEvent) => {
      this.#hash_change(event);
    };

    this.#router_type = "hash";
    window.addEventListener("hashchange", hash_change);
    this.#notify("structure");
    return () => {
      window.removeEventListener("hashchange", hash_change);
      if (this.#router_type === "hash") {
        this.#router_type = undefined;
      }
    };
  }

  navigate(path: string, params?: NavParams, options?: {replace?: boolean}) {
    const router_type = this.router_type;
    if (!router_type) {
      console.log("failed to navigate: no router installed");
      return;
    }

    if (router_type === "hash") {
      // https://origin/some-path?some-path-query#/some-hash-path?some-hash-query#some-hash
      const base_url = new URL(window.location.href);
      const hash = base_url.hash;
      base_url.pathname = "";
      base_url.search = "";
      base_url.hash = "";

      // https://origin/some-hash-path?some-hash-query#some-hash
      const hash_url = new URL(hash.slice(1), base_url);
      // https://origin/some-hash-path
      hash_url.search = "";
      hash_url.hash = "";

      // https://origin/path OR https://origin/some-hash-path/path
      let url = new URL(path, hash_url);
      if (params) {
        for (const [name, value] of Object.entries(params)) {
          if (typeof value === "number") {
            url.searchParams.set(name, value.toString());
          } else if (typeof value === "boolean") {
            url.searchParams.set(name, value ? "true" : "false");
          } else if (typeof value === "string") {
            url.searchParams.set(name, value);
          } else if (value === undefined || value === null) {
            // Ignore undefined and null values.
          } else {
            console.log(`failed to navigate: invalid parameter type for ${name}: ${typeof value}`, path, params);
            return;
          }
        }
      }

      // /some-new-path?some-new-query#some-new-hash
      const new_hash = `${url.pathname}${url.search}${url.hash}`;

      if (options?.replace) {
        const replace_url = new URL(window.location.href);
        replace_url.hash = new_hash;
        window.location.replace(replace_url);
      } else {
        window.location.hash = new_hash ? `#${new_hash}` : "";
      }
    } else {
      console.log(`failed to navigate: unsupported router type: ${router_type}`);
      return;
    }
  }

  #hash_change(event: HashChangeEvent): void {
    this.#update_hash_route(new URL(event.newURL));
  }

  #update_hash_route(base: URL): void {
    const hash = base.hash;
    base.pathname = "";
    base.search = "";
    base.hash = "";

    const url = new URL(hash.slice(1), base);

    this.#match(url);

    if ((!this.#active && !hash) || hash === "#") {
      const initial = this.#find_initial();
      if (initial && initial.#options.path) {
        initial.navigate(initial.#options.path);
      }
    }
  }

  #match(url: URL) {
    const best = this.#best_match(url);
    this.#matched(url, best);
  }

  #best_match(url: URL): NavCtx | undefined {
    for (const item of this.#items) {
      const match = item.#best_match(url);
      if (match) {
        return match;
      }
    }
    if (this.#match_path(url).matched) {
      return this;
    }
  }

  #match_path(url: URL): PathMatch {
    const path = url.pathname;
    if (this.#regexp) {
      const match = path.match(this.#regexp);
      if (match && match.groups) {
        const $path = match.groups["$path"];
        const $nest = match.groups["$nest"];
        delete match.groups["$path"];
        delete match.groups["$nest"];
        return {matched: true, path: $path, nested: $nest, params: {...match.groups}};
      }
    }
    return {matched: false};
  }

  #match_params(url: URL): NavParams | undefined {
    let params: NavParams | undefined = undefined;

    if (this.#options.params) {
      params = {};
      for (const [key, value] of url.searchParams) {
        if (key in this.#options.params) {
          if (this.#options.params[key] === "boolean") {
            let bvalue = true;
            if (value) {
              const nvalue = parseInt(value);
              if (isNaN(nvalue)) {
                const lvalue = value.toLowerCase();
                bvalue = lvalue === "true";
              } else {
                bvalue = !!nvalue;
              }
            }
            params[key] = bvalue;
          } else if (this.#options.params[key] === "number") {
            const nvalue = parseFloat(value);
            if (!isNaN(nvalue)) {
              params[key] = nvalue;
            }
          } else if (this.#options.params[key] === "string") {
            params[key] = value;
          }
        }
      }
    }

    return params;
  }

  #matched(url: URL, nav?: NavCtx): boolean {
    let notify = false;

    for (const item of this.#items) {
      notify = item.#matched(url, nav) || notify;
    }

    const target = nav === this;
    const active = nav ? nav === this || this.#contains(nav) : false;
    const match: PathMatch = nav === this ? this.#match_path(url) : {matched: false};
    const route = nav === this ? match.params : undefined;
    const nested = nav === this ? match.nested : undefined;
    const params = nav === this ? this.#match_params(url) : undefined;

    if (this.#target !== target) {
      this.#target = target;
      notify = true;
    }

    if (this.#active !== active) {
      this.#active = active;
      notify = true;
    }

    if (this.#route !== route) {
      this.#route = route;
      notify = true;
    }

    if (this.#nested !== nested) {
      this.#nested = nested;
      notify = true;
    }

    if (this.#params !== params) {
      this.#params = params;
      notify = true;
    }

    if (notify) {
      this.#notify("match");
    }

    return notify;
  }

  #contained_by(nav: NavCtx): boolean {
    for (let node = this.#parent; node; node = node ? node.#parent : undefined) {
      if (node === nav) {
        return true;
      }
    }

    return false;
  }

  #contains(nav: NavCtx): boolean {
    return nav.#contained_by(this);
  }

  #find_initial(): NavCtx | undefined {
    // Perform width-first search for first "initial" nav item
    let items: NavCtx[] = [this];
    while (items.length > 0) {
      for (const item of items) {
        if (item.#options.initial && item.#options.path) {
          return item;
        }
      }
      items = items.flatMap((item) => item.#items);
    }
  }

  get router_type(): NavRouterType | undefined {
    return this.#router_type ? this.#router_type : this.#parent ? this.#parent.router_type : undefined;
  }

  get target(): boolean {
    return this.#target;
  }

  get targeted(): Readable<NavCtx | undefined> {
    return this.#targeted;
  }

  get active(): boolean {
    return this.#active;
  }

  get route(): RouteParams | undefined {
    return this.#route;
  }

  get nested(): string | undefined {
    return this.#nested;
  }

  get params(): NavParams | undefined {
    return this.#params;
  }

  get item(): NavItem {
    const item = {
      ...this.#options,
      target: this.#target,
      active: this.#active,
      items: this.#items.map((nav) => nav.item),
    };

    if (typeof item.path === "string" && this.router_type === "hash" && !this.#options.external) {
      item.path = `#${item.path}`;
    }

    return item;
  }

  #notify(reason: "structure" | "match") {
    for (const run of this.#subscriptions) {
      run(this);
    }
    if (reason !== "match" && this.#router_type === "hash") {
      this.#update_hash_route(new URL(window.location.href));
    }
    if (reason !== "match" && this.#parent) {
      this.#parent.#notify(reason);
    }
  }
}
